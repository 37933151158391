import usePrefix from '@/hooks/usePrefix';
import { googleMapsApiKey } from '@/utils/googleMaps';
import { Autocomplete, LoadScriptNext } from '@react-google-maps/api';
import { useMobxStores } from '@stores/index';
import {
  getAirportLocalStorage,
  isServer,
  setAirportLocalStorage,
} from '@utils/isServer';
import { AutoComplete as AutoCompleteAntd, Button, Input, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState } from 'react';
import { IAirportValue, ICityValue, ISearch } from '../types';
import { DatePicker } from './DatePicker';
import { EnvironmentFilled, ArrowRightOutlined } from './Icons';
import { modalType } from './Modal';

const { RangePicker } = DatePicker;
const Libraries: any = ['geometry', 'places'];

function SearchBC({
  searchType,
  airports,
  title,
  selectedAirport,
  checkin,
  checkout,
  filterType,
}: ISearch): ReactElement {
  const { searchStore } = useMobxStores(),
    router = useRouter(),
    prefix = usePrefix(),
    [dateRange, setDaterange] = useState({
      checkIn: checkin ? checkin : dayjs().format(),
      checkOut: checkout ? checkout : dayjs().add(1, 'day').format(),
    }),
    [airportValue, setAirportValue] = useState<IAirportValue>(), //airport
    [cityAutocomplete, setCityAutocomplete] = useState(null),
    [cityValue, setCityValue] = useState<ICityValue>(), //city
    autocompleteAirportOptions = airports?.result
      ?.filter((x) => filterType == 'all' || x.airport_display_type == 0)
      .map((airport) => {
        return {
          value: `${airport.airport_initials} - ${airport.airport_name}`,
          id: airport.airport_id,
          airportinitials: airport.airport_initials,
        };
      });
  const autocompleteCruiseOptions = airports?.result
    ?.filter((x) => x.airport_display_type == 2)
    .map((airport) => {
      return {
        value: `${airport.airport_initials} - ${airport.airport_name}`,
        id: airport.airport_id,
        airportinitials: airport.airport_initials,
        lat: airport.airport_latitude,
        lng: airport.airport_longitude,
      };
    });

  function handleAutocompleteSelect({
    _value,
    option,
  }: {
    _value;
    option;
  }): void {
    setAirportLocalStorage(searchType, {
      value: option.value,
      id: option.id,
      airportinitials: option.airportinitials,
    });
    setAirportValue({
      value: option.value,
      id: option.id,
      airportinitials: option.airportinitials,
    });
    if (!isServer && router.pathname.includes('search')) {
      sessionStorage.setItem(
        'airport',
        JSON.stringify({
          value: option.value,
          id: option.id,
          airportinitials: option.airportinitials,
        })
      );
    }
  }

  function handleFilterAutocomplete({
    inputValue,
    option,
  }: {
    inputValue;
    option;
  }): boolean {
    return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  }

  function disabledDate(current): boolean {
    return current && current < dayjs().startOf('day');
  }

  function handleSearchValidations(): boolean {
    if (searchType === 'airport' || searchType === 'cruise') {
      if (airportValue?.value === null || airportValue?.value === '') {
        modalType({
          type: 'error',
          title: 'Alert',
          content: 'What is the Airport?',
        });
        return false;
      }
    } else {
      if (
        typeof cityValue?.formatted_address === 'undefined' ||
        cityValue.formatted_address === ''
      ) {
        modalType({
          type: 'error',
          title: 'Alert',
          content: 'Please inform a valid US place or address.',
        });
        return false;
      }
    }

    if (dateRange.checkIn === '' || dateRange.checkOut === '') {
      modalType({
        type: 'error',
        title: 'Alert',
        content: 'What is the Start and End date?',
      });

      return false;
    }
    if (dayjs(dateRange.checkIn).isSame(dateRange.checkOut)) {
      modalType({
        type: 'error',
        title: 'Alert',
        content: `Please choose a End date that is later than ${dayjs(
          dateRange.checkIn
        ).format('MM/DD/YYYY')}`,
      });
      return false;
    }

    if (dayjs(dateRange.checkOut).isBefore(dateRange.checkIn)) {
      modalType({
        type: 'error',
        title: 'Alert',
        content: `Please choose a End date that is later than ${dayjs(
          dateRange.checkOut
        ).format('MM/DD/YYYY')}`,
      });
      return false;
    }
  }

  function handleOnPlaceChanged() {
    if (cityAutocomplete) {
      const { formatted_address, geometry, html_attributions }: ICityValue =
        cityAutocomplete.getPlace();
      sessionStorage.setItem(
        'city',
        JSON.stringify({
          formatted_address,
          geometry,
          html_attributions,
        })
      );
      setCityValue({
        formatted_address,
        geometry,
        html_attributions,
      });
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  }

  function handleAirportCityRedirect(): void {
    let routerUrl = '';
    const query = {};

    if (searchType === 'airport' || searchType === 'cruise') {
      routerUrl = prefix + '/reservation/search';
      Object.assign(query, {
        initials: airportValue.airportinitials,
        airport: airportValue.value,
        _$i: !isServer && window.btoa(airportValue?.id?.toString()),
      });
    } else if (searchType === 'city') {
      Object.assign(query, {
        city: cityValue.formatted_address,
        lat: JSON.parse(sessionStorage.getItem('city')).geometry.location.lat,
        lng: JSON.parse(sessionStorage.getItem('city')).geometry.location.lng,
      });
      routerUrl = prefix + '/reservation/citysearch';
    }

    router.push({
      pathname: routerUrl,
      query: Object.assign(query, {
        checkin: dayjs(dateRange.checkIn).format('YYYY-MM-DD'),
        checkout: dayjs(dateRange.checkOut).format('YYYY-MM-DD'),
      }),
    });
  }

  function handleAutoCompleteAirportChange(): void {
    !isServer && sessionStorage.removeItem('airport');
    setAirportValue({
      value: '',
      id: null,
      airportinitials: '',
    });
  }

  function handleAutoCompleteCityChange(e): void {
    setCityValue(e.target.value);
    !isServer && sessionStorage.removeItem('city');
  }

  function handleRangePickerOnChange(dateStrings): void {
    const [checkIn, checkOut] = dateStrings;
    setDaterange({ checkIn, checkOut });
  }

  function handleMobileDateOnChange({ type, date }: { type; date }): void {
    if (type === 'checkin') {
      setDaterange({ ...dateRange, checkIn: date });
    } else {
      setDaterange({ ...dateRange, checkOut: date });
    }
  }

  useEffect(() => {
    if (
      (searchType === 'airport' || searchType === 'cruise') &&
      !selectedAirport
    ) {
      setAirportValue({
        value: router.query?.airport?.toString() || null,
        id: router.query._$i
          ? !isServer && Number(window.atob(router.query?._$i?.toString()))
          : null,
        airportinitials: router.query?.initials?.toString() || null,
      });
    }
    if (selectedAirport) {
      const data = JSON.parse(selectedAirport);
      setAirportValue({
        value: data?.value || null,
        id: Number(data?.id) || null,
        airportinitials: data?.airportinitials || null,
      });
    }
  }, [router.query, searchType, selectedAirport]);

  return (
    <div className="flex flex-col mb-2">
      <p className="font-bold my-2">Destination</p>
      <div className="md:bg-white rounded-md border border-gray-300 flex flex-auto flex-wrap items-center justify-start h-10 w-full mb-4 bg-white">
        {(searchType === 'airport' || searchType === 'cruise') && (
          <AutoCompleteAntd
            className="w-full border-white hover:border-white"
            options={
              searchType === 'airport'
                ? autocompleteAirportOptions
                : autocompleteCruiseOptions
            }
            filterOption={(inputValue, option) =>
              handleFilterAutocomplete({ inputValue, option })
            }
            defaultValue={
              router.query?.airport
                ? router.query?.airport?.toString()
                : selectedAirport
                ? JSON.parse(selectedAirport)?.value
                : getAirportLocalStorage(
                    searchType,
                    setAirportValue,
                    airportValue
                  )
            }
            onSelect={(value, option) =>
              handleAutocompleteSelect({ _value: value, option })
            }
            disabled={router.pathname.includes('parking')}
          >
            <Input
              data-test-id="airport"
              bordered={false}
              prefix={<EnvironmentFilled />}
              placeholder="Where do you want to park?"
              className="border-none ring-0 hover:border-white px-2 focus:outline-none input-search-airport-city"
              onChange={handleAutoCompleteAirportChange}
            />
          </AutoCompleteAntd>
        )}

        {searchType === 'city' && (
          <LoadScriptNext
            googleMapsApiKey={googleMapsApiKey}
            libraries={Libraries}
            onLoad={() => searchStore.setIsLoadedGoogle(true)}
          >
            <Autocomplete
              onLoad={(autocomplete) => setCityAutocomplete(autocomplete)}
              onPlaceChanged={handleOnPlaceChanged}
              restrictions={{ country: 'us' }}
              fields={['geometry.location', 'formatted_address']}
              className="w-full border-white hover:border-white px-0"
            >
              <div className="flex align-center overflow-hidden input-search-airport-city px-2">
                <span className="flex items-center mr-1">
                  <EnvironmentFilled />
                </span>
                <input
                  data-test-id="address"
                  placeholder="Enter a city or address"
                  className="border-white hover:border-white w-full focus:outline-none flex flex-grow-1 search-placeholder-color bg-white"
                  defaultValue={
                    router.query?.city
                      ? router.query?.city?.toString()
                      : selectedAirport
                      ? JSON.parse(selectedAirport)?.value
                      : null
                  }
                  type="search"
                />
              </div>
            </Autocomplete>
          </LoadScriptNext>
        )}
      </div>

      <div className="hidden md:flex flex-row mb-2">
        <p className="flex-1 font-bold">Start Date</p>
        <p className="flex-1 font-bold">End Date</p>
      </div>
      <div className="hidden md:grid grid-cols-1 rounded-md bg-white w-full h-10 border border-gray-300 mb-4">
        <RangePicker
          bordered={false}
          data-test-id="range_picker"
          placeholder={['Select Date', 'Select Date']}
          size="small"
          className="w-full"
          format="MM/DD/YYYY"
          defaultValue={[
            typeof checkin !== 'undefined'
              ? dayjs(dayjs(checkin), 'MM/DD/YYYY')
              : null,
            typeof checkout !== 'undefined'
              ? dayjs(dayjs(checkout), 'MM/DD/YYYY')
              : null,
          ]}
          onChange={(_dates, dateStrings) =>
            handleRangePickerOnChange(dateStrings)
          }
          separator={<ArrowRightOutlined />}
          disabledDate={disabledDate}
        />
      </div>

      <div className="md:bg-transparent py-4 md:py-0 rounded-md md:hidden grid grid-cols-2 justify-between gap-4 w-full">
        <div className="flex flex-col w-full">
          <label className="font-bold" htmlFor="checkIn">
            Start Date
          </label>
          <Input
            className="w-full pt-2 md:pt-0"
            data-test-id="mob_start_date"
            title="abc"
            type="date"
            defaultValue={dayjs(dateRange.checkIn).format('YYYY-MM-DD')}
            pattern="\d{4}-\d{2}-\d{2}"
            placeholder="Starting parking at"
            onChange={(evt) => {
              handleMobileDateOnChange({
                type: 'checkin',
                date: dayjs(evt.target.value).format('MM/DD/YYYY'),
              });
            }}
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="font-bold" htmlFor="checkOut">
            End Date
          </label>
          <Input
            className="w-full pt-2 md:pt-0"
            data-test-id="mob_end_date"
            type="date"
            inputMode="none"
            defaultValue={dayjs(dateRange.checkOut).format('YYYY-MM-DD')}
            pattern="\d{4}-\d{2}-\d{2}"
            placeholder="End parking at"
            onChange={(evt) => {
              handleMobileDateOnChange({
                type: 'checkout',
                date: dayjs(evt.target.value).format('MM/DD/YYYY'),
              });
            }}
          />
        </div>
      </div>
      <Button
        size="large"
        data-test-id="park_now"
        type="primary"
        block
        className="rounded-full text-base font-semibold hover:bg-opacity-75 w-full self-center"
        onClick={() => {
          if (handleSearchValidations() === false) return false;
          handleAirportCityRedirect();
        }}
      >
        {router.pathname.includes('search') ? 'Search' : 'Find a spot'}
      </Button>
    </div>
  );
}

export default observer(SearchBC);
