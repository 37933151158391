import React, { useState, useEffect } from 'react';
import { CDN2 } from '@/helpers/api';
import { Button, Divider, Modal } from 'antd';
import Image from 'next/image';
import { pushLayerEvent } from '@/utils/gtag';
import ButtonStore from '@/components/ButtonStore';
import { CloseOutlined } from './Icons';

interface IModalDownload {
  mobileType?: string;
  deviceType?: string;
}

export default function ModalDownloadApp({
  mobileType,
  deviceType,
}: IModalDownload) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    if (mobileType === 'ios') {
      pushLayerEvent('nosonOpenIosStore', {});
    } else {
      pushLayerEvent('nosonOpenAndroidStore', {});
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (sessionStorage.getItem('show') == '1') {
        return;
      }
      sessionStorage.setItem('show', '1');
      setIsModalOpen(true);
    }, 500);
  }, []);

  return (
    <div className="flex flex-col">
      <Modal
        className="modal-download-app"
        visible={isModalOpen}
        closable={false}
        onCancel={handleCancel}
        title={
          <div className="flex justify-end -mb-4">
            <CloseOutlined onClick={handleCancel} />
          </div>
        }
        footer={
          <p
            className="text-secondary font-bold cursor-pointer text-center"
            onClick={handleCancel}
          >
            Thanks, but I'd prefer to stay on the website
          </p>
        }
        destroyOnClose={true}
      >
        <div className="flex flex-col items-center mx-auto">
          <Image
            src={CDN2() + '/staticmyapp/logo-registred.svg'}
            width="180"
            height="100"
            alt="On Air Parking"
            priority
            quality={100}
          />
          <p className="text-lg text-center px-4">
            Additional $2 off when using the app!
          </p>
          <div className="flex flex-row justify-center items-center mt-8 gap-x-4">
            {deviceType === 'mobile' ? (
              <a
                href={
                  mobileType === 'ios'
                    ? 'https://apps.apple.com/us/app/onairparking/id6444409018'
                    : 'https://play.google.com/store/apps/details?id=io.noson.onairparking&pli=1'
                }
                target="_blank"
                rel="noopener noreferrer"
                className="bg-primary text-base font-bold text-white py-2.5 px-12 text-center hover:text-primary rounded-full"
                onClick={handleOk}
              >
                Download App
              </a>
            ) : (
              <ButtonStore width={160} height={50} />
            )}
          </div>
          <Divider className="-mb-2">or</Divider>
        </div>
      </Modal>
    </div>
  );
}
